import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const AboutUs = () => {
    const data = useStaticQuery(query);

    return (
        <Layout pageInfo={{ pageName: "index" }}>
            <SEO title="About Us" keywords={[`gatsby`, `react`, `bootstrap`]} />
            <div className="container">
                <div className="row pt-5">
                    <div className="col-md-12 gPadding">
                        <div className="display-3">{data.strapiAbout.title}</div>
                        <div className="a2 about">{data.strapiAbout.subtitle}</div>
                        <hr></hr>
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-md-6 gPadding">
                        <div className="a1">{data.strapiAbout.paragraph1_title}</div>
                        <p>{data.strapiAbout.paragraph1_description}</p>
                    </div>
                    <div className="col-md-6 crop-height gPadding">
                        <img src={data.strapiAbout.paragraph1_picture.publicURL} className="scale" alt="Group People" />
                    </div>
                </div>
            </div>
            <div className="container-fluid" style={{ backgroundColor: '#F1F3F4' }}>
                <div className="container">
                    <div className="row separatorUp separatorDown">
                        <div className="col-md-6 crop-height gPadding d-none d-md-block">
                            <img src={data.strapiAbout.paragraph2_picture.publicURL} className="scale" alt="Group People" />
                        </div>
                        <div className="col-md-6 gPadding">
                            <div className="a1">{data.strapiAbout.paragraph2_title}</div>
                            <p>{data.strapiAbout.paragraph2_description}</p>
                        </div>
                        <div className="col-md-6 crop-height gPadding d-md-none">
                            <img src={data.strapiAbout.paragraph2_picture.publicURL} className="scale" alt="Group People" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row separatorUp separatorDown">
                    <div className="col-md-6 gPadding">
                        <div className="a1">{data.strapiAbout.paragraph3_title}</div>
                        <p>{data.strapiAbout.paragraph3_description}</p>
                    </div>
                    <div className="col-md-6 crop-height gPadding">
                        <img src={data.strapiAbout.paragraph3_picture.publicURL} className="scale" alt="Group People" />
                    </div>
                </div>
            </div >
            <div className="container-fluid" style={{ backgroundColor: '#F1F3F4' }}>
                <div className="container">
                    <div className="row separatorUp separatorDown">
                        <div className="col-md-6 crop-height gPadding d-none d-md-block">
                            <img src={data.strapiAbout.paragraph4_picture.publicURL} className="scale" alt="Group People" />
                        </div>
                        <div className="col-md-6 gPadding">
                            <div className="a1">{data.strapiAbout.paragraph4_title}</div>
                            <p>{data.strapiAbout.paragraph4_description}</p>
                        </div>
                        <div className="col-md-6 crop-height gPadding d-md-none">
                            <img src={data.strapiAbout.paragraph4_picture.publicURL} className="scale" alt="Group People" />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const query = graphql`
  query {
    strapiAbout {
        title
        subtitle
        paragraph1_title
        paragraph1_description
        paragraph1_picture {
            publicURL
        }
        paragraph2_title
        paragraph2_description
        paragraph2_picture {
            publicURL
        }
        paragraph3_title
        paragraph3_description
        paragraph3_picture {
            publicURL
        }
        paragraph4_title
        paragraph4_description
        paragraph4_picture {
            publicURL
        }
      }
  }
`;

export default AboutUs;
